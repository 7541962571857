<template>
  <div>
    <Card v-if="signImg.length > 0" :bordered="false" dis-hover>
      <p slot="title">用户/用户委托人签字</p>
      <div class="img-list">
        <div
          style="width: 400px; height: 200px"
          v-bind:style="{
            'background-image': 'url(' + signImg + ')',
            'background-size': 'contain',
            'background-position': 'center center',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
          }"
        ></div>
      </div>
      <div style="padding: 5px">
        <Button @click="handleReSign" long style="width: 100%; height: 35px">重签</Button>
      </div>
    </Card>
    <div v-else>
      <Card :bordered="false" dis-hover>
        <p slot="title">用户/用户委托人签字</p>
        <vue-esign
          ref="esign"
          :width="800"
          :height="300"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </Card>
      <div style="padding: 5px">
        <Button @click="handleReset" long style="width: 100%; height: 35px"
          >清空</Button
        >
      </div>
      <div style="padding: 5px">
        <Button
          type="success"
          @click="handleGenerate"
          style="width: 100%; height: 35px"
          >完成</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
// 局部
import vueEsign from "vue-esign";
import { put, signatureUrl, getFileNameUUID } from "../../utils/aliOss";

export default {
  data() {
    return {
      signImg: this.signImgUrl,
      WarrantyNum: this.p_WarrantyNum,
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
    };
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset();
    },
    handleReSign(){
      console.log('ss');
      this.$emit('reSign')
    },
    handleGenerate() {
      var that = this;
      this.$refs.esign
        .generate()
        .then((resp) => {
          this.resultImg = resp;
          var blob = dataURLtoBlob(resp);
          let objName = getFileNameUUID();
          var file = blobToFile(blob, objName);
          // 获取文件的后缀名
          // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
          put(`Images/upload/${this.WarrantyNum}/sign/${objName}.jpg`, file).then(
            (res) => {
              console.log("上传成功", res);
              that.$emit('uploadSuccess',objName)
              // 上传成功之后，转换真实的地址
              // signatureUrl(`flieName/${objName}`).then((res) => {
              //   console.log(res);
              // });
            }
          );
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
  },
  props: {
    signImgUrl: "",
    p_WarrantyNum: "",
  },
  watch: {
    signImgUrl(newValue) {
      /**
       * 监听value的值的变化,响应式改变this.watchPropsValueObj对象
       */
      this.signImg = newValue;
    },
    p_WarrantyNum(newValue) {
      this.WarrantyNum = newValue;
    },
  },
  components: { vueEsign },
};

//将base64转换为blob
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

//将blob转换为file
function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}
</script>
