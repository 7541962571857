<template>
  <div class="listform">
    <div class="form-title">
      水工档案列表
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <!-- <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button> -->
      <!-- <Button @click="MaterialIntegralReward()" type="primary" icon="ios-clipboard">阶段物资积分奖励</Button> -->
      <Button @click="AddBlack()" type="primary" icon="ios-clipboard">加入黑名单</Button>
    </div>
    <Modal title="积分手动核减" width="400" height="800" v-model="IntegralSubtract_modal.show" @on-ok="ExChange_ModalOK" @on-cancel="ModalCancel">
      <div style="margin-bottom:10px;">
        <p>数量：</p>
        <Input v-model="IntegralSubtract_modal.changeintegral" placeholder="填写要核减的积分数量"></Input>
      </div>
      <div>
        <p>备注：</p>
        <Input v-model="IntegralSubtract_modal.remark" type="textarea" placeholder="填写要核减积分备注记录"></Input>
      </div>
    </Modal>
    <Modal title="加入黑名单" width="500" height="800" v-model="Black_modal.show" @on-ok="Black_ModalOK" @on-cancel="ModalCancel">
      <div style="margin-bottom:10px;">
        <p>手机号码</p>
        <Input v-model="Black_modal.sdworkertel" placeholder="请输入积分号码"></Input>
      </div>
      <div style="margin-top:10px;">
        <p>原因</p>
        <RadioGroup v-model="Black_modal.reason">
          <Radio v-for="item in Black_modal.BlackReason" :label="item.ID" v-bind:key="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </div>
      <div style="margin-top:10px;">
        <p>其他备注</p>
        <Input v-model="Black_modal.otherremark" type="textarea" placeholder="请输入其他备注"></Input>
      </div>
    </Modal>
    <Modal v-model="editInfoModal.show" title="修改水工档案资料" @on-ok="EditSDWorkerInfoHandler">
      <Form :model="tempData" :label-width="100">
        <FormItem label="姓名 ">
          <Input v-model="tempData.Name" placeholder="输入要修改的水工姓名"></Input>
        </FormItem>
      </Form>
    </Modal>
    <div class="datatable">
      <Table
        :border="true"
        size="small"
        @on-sort-change="Sort"
        :columns="table.tablecolumsTitle"
        :loading="table.loading"
        :data="table.datalist"
      ></Table>
    </div>
    <div class="footerpage">
      <Page
        :total="table.total"
        :page-size="table.pageSize"
        @on-change="PageChange"
        :current="table.pageIndex"
        show-elevator
      ></Page>
    </div>

  </div>
</template>
<script>
export default {
  name:"dealer_sdworkerlist",
  data() {    
    return {
      sel_field: "",
      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" },
        // { value: "ServiceCount", label: "试压户数" },
        // { value: "PeriodServiceCount", label: "9.1单数" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "Name",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "Tel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "试压户数",
            key: "ServiceCount",
            width: 80,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "目前剩余积分",
            key: "Integral",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "已兑换积分",
            key: "ExChangeIntegral",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          // {
          //   title: "物资积分",
          //   key: "MaterialIntegral",
          //   align: "center",
          //   tooltip: true,
          //   sortable: "custom"
          // },
          {
            title: "锁定积分",
            key: "LockIntegral",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          // {
          //   title: "身份",
          //   key: "IdentityDesc",
          //   align: "center",
          //   tooltip: true
          // },
          {
            title: "水工类型",
            key: "SDWorkerType",
            width: 120,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color = (row.SDWorkerType == 0 && "primary") || (row.SDWorkerType == 1 && "success") || "error";
              const text = (row.SDWorkerType == 0 && "普通") || (row.SDWorkerType == 1 && "核心") || "竞品";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "维护人",
            key: "EmployeeName",
            width: 90,
            align: "center",
            tooltip: true
          },
          {
            title: "首次录入时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "状态",
            key: "Status",
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 0 && "success") || "error";
              const text = (row.Status == 0 && "正常") || "黑名单";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "积分记录",
            key: "TelNum",
            width: 260,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              // 2022.5.6新增岳阳分公司
              // 2022.6.27新增株洲核减
              // 2022.9.19新增武汉核减
              if(params.row.AgentId==80||params.row.AgentId==81||params.row.AgentId==56){
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "success", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.IntegralLogList(params.row);
                        }
                      }
                    },
                    "积分记录"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.WorkLogList(params.row);
                        }
                      }
                    },
                    "试压记录"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "error", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.IntegralSubtract(params.row);
                        }
                      }
                    },
                    "积分核减"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "Primary", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.EditSDWorkerInfoModal(params.row);
                        }
                      }
                    },
                    "修改"
                  )
                ]);
              }else{
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "success", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.IntegralLogList(params.row);
                        }
                      }
                    },
                    "积分记录"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.WorkLogList(params.row);
                        }
                      }
                    },
                    "试压记录"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "Primary", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.EditSDWorkerInfoModal(params.row);
                        }
                      }
                    },
                    "修改"
                  )
                ]);
              }
              
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
      modal:{
        show:false
      },
      // mat_integral_reward: {
      //   dateSearchValue: "",
      //   url: "",
      //   remark: ""
      // },
      IntegralSubtract_modal: {
        currentsdworkerid: 0,
        show: false,
        changeintegral: 0,
        remark: ""
      },
      Black_modal: {
        show: false,
        sdworkertel: "",
        reason: 0,
        otherremark: "",
        BlackReason: [],
      },
      tempData: {
        Name:''
      },
      editInfoModal: {
        show: false,
        loading: true
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_sdworkerlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorker/GetSDWorkerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          // res.data.ListData.map(item => {
          //   item.IdentityDesc =
          //     (item.Identity == 0 && "水工") ||
          //     (item.Identity == 1 && "项目经理") ||
          //     "数据异常";
          // });
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      var Operater = 0;
      if (
        this.sel_field == "PeriodServiceCount" ||
        this.sel_field == "ServiceCount"
      ) {
        Operater = 3;
      }
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: Operater,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    IntegralLogList: function(e) {
      this.$router.push({
        name: "dealer_sdworkerintegrallog",
        params: { sdworkerid: e.ID }
      });
    },
    WorkLogList: function(e) {
      this.$router.push({
        name: "dealer_sdworkerlog",
        params: { sdworkerid: e.ID }
      });
    },
    ExportExcel: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("SDWorker/ExportSDWorkerExcel", {}).then(res => {
        that.$Spin.hide();
        window.open(that.$config.fileUrl + res.data);
      });
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    //物资积分奖励弹窗
    // MaterialIntegralReward:function(){
    //       this.modal.show = true;
    //       this.mat_integral_reward.url = "SDWorker/MaterialIntegralReward";
    //       this.mat_integral_reward.dateSearchValue = "";
    //       this.mat_integral_reward.remark =
    //         "请选择要计算的日期范围";
    // },

    // ModalOK: function() {
    //   var that = this;
    //   this.$Spin.show({
    //     render: h => {
    //       return h("div", [
    //         h("Icon", {
    //           class: "demo-spin-icon-load",
    //           props: {
    //             type: "ios-loading",
    //             size: 18
    //           }
    //         }),
    //         h("div", "操作进行中..")
    //       ]);
    //     }
    //   });
    //   if (that.mat_integral_reward.dateSearchValue == "") {
    //     this.$Modal.warning({
    //       title: "提示",
    //       content: "请选择日期范围"
    //     });
    //     return;
    //   }
    //   this.$http
    //     .get(that.mat_integral_reward.url, {
    //       params: {
    //         startdate: that.mat_integral_reward.dateSearchValue[0],
    //         enddate: that.mat_integral_reward.dateSearchValue[1]
    //       }
    //     })
    //     .then(res => {
    //       that.$Spin.hide();
    //       window.open(that.$config.fileUrl + res.data);
    //     });
    // },
    ExChange_ModalOK: function() {
      var that = this;
      var r = /^\+?[1-9][0-9]*$/;
      if (!r.test(that.IntegralSubtract_modal.changeintegral)) {
        this.$Message.warning("请输入一个正整数！");
        return;
      }
      if (that.IntegralSubtract_modal.remark.length <= 0) {
        this.$Message.warning("必须填写核减备注");
        return;
      }
      that.$http.get('SDWorker/SDWorkerExchangeIntegral',{
        params:{
          changeintegral:that.IntegralSubtract_modal.changeintegral,
          sdworkerid:that.IntegralSubtract_modal.currentsdworkerid,
          remark:that.IntegralSubtract_modal.remark
        }
      }).then(res=>{
        if(res.data!='success'){
          this.$Message.warning(res.data);
        }else{
          this.$Message.info('核减成功!');
          that.GetTableList();
          that.IntegralSubtract_modal.changeintegral=0;
          that.IntegralSubtract_modal.remark="";
          that.IntegralSubtract_modal.show=false;
        }
      });
    },
    // 积分核减弹窗
    IntegralSubtract: function(row) {
      this.IntegralSubtract_modal.show = true;
      this.IntegralSubtract_modal.currentsdworkerid = row.ID;
    },
    // 加入黑名单
    AddBlack: function() {
      this.Black_modal.show = true;
      this.GetCheckbox();
    },
    Black_ModalOK: function() {
      var that = this;
      if (that.Black_modal.sdworkertel.length <= 0) {
        this.$Message.warning("请填写手机号码");
        return;
      }
      if (that.Black_modal.reason == 0) {
        this.$Message.warning("请选择原因");
        return;
      }
      that.$http.get('SDWorker/AddBlack',{
        params:{
          sdworkertel:that.Black_modal.sdworkertel,
          reason:that.Black_modal.reason,
          otherremark:that.Black_modal.otherremark
        }
      }).then(res=>{
        if(res.data!='success'){
          this.$Message.warning(res.data);
        }else{
          this.$Message.info('操作成功!');
          that.GetTableList();
          that.Black_modal.sdworkertel="";
          that.Black_modal.reason=0;
          that.Black_modal.otherremark="";
          that.Black_modal.show=false;
        }
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=22").then(res => {
        that.Black_modal.BlackReason = res.data;
      });
    },
    EditSDWorkerInfoHandler() {
      var that = this;
      this.$http.post("SDWorker/EditSDWorker", this.tempData).then(res => {
        that.editInfoModal.show = false;
        that.GetTableList();
      });
    },
    //修改资料弹窗
    EditSDWorkerInfoModal: function(row_data) {
      var that=this;      
      var id=row_data.ID;
      that.table.loading = true;
      this.$http.get("SDWorker/GetSDWorker",{params:{id:id}}).then(res=>{
        console.log(res.data.Data);
        that.tempData=res.data.Data; 
        that.editInfoModal.show = true;
        that.table.loading = false;
      })
    },
    // RewardIntegralList: function(e) {
    //   this.$router.push({
    //     name: "dealer_rewardIntegral",
    //     params: { }
    //   });
    // },

  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 