<template>
  <div class="mo-imgupload">
    <!-- <van-nav-bar title="活动中心" left-text="" left-arrow @click-left="onClickLeft()">
    </van-nav-bar> -->
    <h3 style="color:red;text-align:center;margin-top:5px;">天力产品质保卡</h3>
    <p style="margin:10px 5px 5px 5px;font-weight:bold;">尊敬的用户：您好！</p>
    <p style="margin:5px 5px 5px 5px;">
      &nbsp;&nbsp;&nbsp;为了保证您家中管道系统施工质量及安全，敬请阅读本质保卡，了解相关知识，维护您的合法权益。
    </p>

    <!-- <van-tabs v-model="active" @click="tabchange" :sticky="true"> -->
    <!-- 服务单 -->
    <!-- <van-tab :title="' 服务单 '"> -->
    <Card :bordered="true" style="margin:3px;">
      <div>
        <p style="font-size: 18px;text-align: center;font-weight:bold;">
          无忧管家服务单
        </p>
      </div>
      <!-- <p slot="title" style="text-align: center">无忧管家服务单</p> -->
      <Row class="row" style="margin-top:10px;">
        <Col :span="6">用&nbsp;户&nbsp;地&nbsp;址:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj.CustAddress}}</Col>
      </Row>
      <Row class="row">
        <Col :span="6">管&nbsp;道&nbsp;系&nbsp;列:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj.PipeLineName}}</Col>
      </Row>
      <Row class="row">
        <Col :span="6">业&nbsp;主&nbsp;姓&nbsp;名:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj.CustName}}</Col>
      </Row>
      <Row class="row">
        <Col :span="6">电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj.CustTel}}</Col>
      </Row>
      <Row class="row">
        <Col :span="6">保&nbsp;压&nbsp;时&nbsp;间:</Col>
        <Col :span="17" style="margin-left:10px;">
          <Row>起：{{dataobj.StartGuaranteedTime}}</Row>
          <Row>止：{{dataobj.EndGuaranteedTime}}</Row>
          <Row>分钟：{{minute}}</Row>
        </Col>
      </Row>
      <Row class="row">
        <Col :span="6">试&nbsp;&nbsp;&nbsp;压&nbsp;&nbsp;&nbsp;员:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj.WorkName}}</Col>
      </Row>
      <Row class="row">
        <Col :span="6">日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期:</Col>
        <Col :span="17" style="margin-left:10px;">{{dataobj._CreateTime}}</Col>
      </Row>

      <!-- 管路整改须知 -->
      <!-- <div>
        <p style="font-size: 15px; font-weight: bold; margin-top: 15px">
          管路整改须知
        </p>
      </div>
      <div>
        <p style="font-size: 15px">
          &nbsp;&nbsp;&nbsp;
          尊敬的客户，经无忧管家服务专员试压后，您的管路系统安装未能达标，为保障家庭水路系统安全，请您及时整改，整改后我们将为您提供第二次的免费试压服务。
        </p>
        <p style="margin: 5px 5px 5px 5px">
          整改要求：{{
            dataobj.StandardDesc == null ? "" : dataobj.StandardDesc
          }}
        </p>
        <e-Sign
          v-bind:signImgUrl="dataobj.SignImgs"
          :p_WarrantyNum="dataobj.WarrantyNum"
          v-on:uploadSuccess="uploadSuccess"
        ></e-Sign>
      </div> -->
    </Card>
    <!-- </van-tab> -->
    <!-- <van-tab :title="' 使用说明 '"> -->
    <Card :bordered="true" style="margin:3px;">
      <!-- 使用说明 -->
      <div>
        <p style="font-size: 15px; font-weight: bold;">
          产品质量保证：
        </p>
      </div>
      <div style="font-size: 12px;margin-top:10px;">
        <p>
          &nbsp;&nbsp;&nbsp;产品安装质量由安装单位负责。凡由天力当地总经销提供的专业安装，安装质量保证期，按国家及当地的有关规定执行。
        </p>
        <div>
          <p style="font-size:14px;font-weight:bold;margin-top:10px;">天力管在正常使用条件下 ，使用寿命可达50年</p>
        </div>
        <p>
          &nbsp;&nbsp;&nbsp;1.根据国家标准和行业标准的要求 ，要保证水管使用寿命达50年，请在下列环境条件中使用：
        </p>
        <div class="table_detail" style="margin-top:10px;">
          <table>
              <thead>
                <tr>
                  <th>条件项目</th>
                  <th>使用水工作压力</th>
                  <th>使用水温度</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PPR热水管系统</td>
                  <td>0.8Mpa(8公斤)</td>
                  <td>小于70℃</td>
                </tr>
                <tr>
                  <td>PPR冷水管系统</td>
                  <td>1.1Mpa(11公斤)</td>
                  <td>常温冷水</td>
                </tr>
                <tr>
                  <td>铜管道系统</td>
                  <td>1.6Mpa(16公斤)</td>
                  <td>小于95℃</td>
                </tr>
              </tbody>
            </table>
        </div>
        
      </div>
      <!-- <div style="font-size:12px;margin-top:5px;">
            <p>&nbsp;&nbsp;&nbsp;
              1、质保期：指在标准规定使用条件下的最长质保时间。因产品质量以外的因素(施工不当运输损坏、使用维护不当、人为损坏、自然灾害、产品超出储存要求等)导致产品出现的问题，不在我公司质保范围之内；</p>
            <p>&nbsp;&nbsp;&nbsp;
              2、质保期外，本公司提供相关的有偿服务。如配套服务、技术支持、维修服务等；</p>
            <p>&nbsp;&nbsp;&nbsp;
              3、其他配件及相关产品，按国家法律法规执行；</p>
            <p>&nbsp;&nbsp;&nbsp;
              4、滤芯产品属损耗品，不享受质保。</p>
            <p>&nbsp;&nbsp;&nbsp;
              5、堵头属于临时使用产品，不质保。</p>
      </div> -->
      <!-- 例外条款 -->
      <div>
        <p style="font-size: 14px; font-weight: bold; margin-top: 15px">
          例外条款(下列情况或条件下，不属于上述质量保证的范围)
        </p>
      </div>
      <div style="font-size: 12px">
        <p>
          &nbsp;&nbsp;&nbsp;1、管材或管件不是我公司产品，或者部分不是我公司产品。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;2、客户擅自改道、加接、拆卸、移动等、或其他外力导致的损坏。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;3、未在上述规定的环境条件内使用，或将冷水管用作热水管的用途。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;4、因天气、自然灾害、火灾等不可抗力的因素造成的损坏，如:冬天冻裂、水锤等原因。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;5、天力管与其它材质的管道系统、其它品牌的管道系统、阀门、龙头、管堵等相连接的部位，因连接原因而产生的渗漏。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;6、PP-R管道埋地敷设时不应与防水材料或有气味的有机材料直接接触。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;7、我们提供的管堵是作为试压工作之用，如果您有不准备使用的出口水，请通知我们改装为管帽，或自行更换为铜质管堵。管堵不属质量保证范围。
        </p>
      </div>
    </Card>
    <Card :bordered="true" style="margin:3px;">

      <div v-if="dataobj.StandardDesc!=null">
        <p style="font-size: 18px;text-align: center;font-weight:bold;">
          管路整改须知
        </p>
        <div style="margin-top:5px;margin-top:10px;">&nbsp;&nbsp;&nbsp;尊敬的用户，经无忧管家服务专员试压，您的管道系统中 <div style="text-decoration: underline;">{{dataobj.StandardDesc}}</div> 未能达到验收标准，不予质保。为保障家庭水路系统安全，请您及时整改，整改后我们将为您提供第二次的免费试压服务。</div>
      </div>
      <!-- <p slot="title" style="text-align:center;">管路整改须知</p> -->
      
      <!-- <div style="margin-top:5px;">本人已阅读并被告知了质保卡服务承诺、温馨提示、整改须知、质量保证以及试压服务结果，本人已充分了解并接受前述全部内容。</div> -->
      <p style="margin:5px 5px 5px 5px;font-weight: bold;">
        &nbsp;&nbsp;&nbsp;本人已阅读并被告知了质保卡服务承诺、温馨提示、整改须知、质量保证以及试压服务结果，本人已充分了解并接受前述全部内容。
      </p>
      <e-Sign v-bind:signImgUrl="dataobj.SignImgs"  :p_WarrantyNum="dataobj.WarrantyNum"   v-on:reSign="reSign" v-on:uploadSuccess="uploadSuccess"></e-Sign>
    </Card> 
    <!-- </van-tab> -->
    <!-- 温馨提示 -->
    <!-- <van-tab :title="' 温馨提示 '"> -->
    <Card :bordered="true" style="margin:3px;">
      <div>
        <p style="font-size: 18px;text-align: center;font-weight:bold;">
          管道保养温馨提示
        </p>
      </div>
      <!-- <p slot="title" style="text-align:center;">管道保养温馨提示</p> -->
      <div style="font-size:12px;margin-top:10px;">
        <p>&nbsp;&nbsp;&nbsp;
          1、PP-R管长期直接暴露或阳光照射或紫外线辐射下易老化降解，明装管材与管件必须用铝箔或者防紫外线材料进行遮光、保温处理；</p>
        <p>&nbsp;&nbsp;&nbsp;
          2、如离家几天，建议关闭管道总阀，冬天必须排空管路余水；</p>
        <p>&nbsp;&nbsp;&nbsp;
          3、注意对管路系统进行保护，以免后续装修施工因操作不当损坏管路系统；</p>
        <p>&nbsp;&nbsp;&nbsp;
          4、PP-R管道严禁与有机材料直接接触，如沥青、防水涂料、油漆、油污等；</p>
        <p>&nbsp;&nbsp;&nbsp;
          5、试压所有堵头仅为试压所用，不属质量保证范围，长期不使用应更换金属堵头，确保安全；</p>
        <p>&nbsp;&nbsp;&nbsp;
          6、2-3年后定期对热燃设备（如热水器）进行检测，避免设备故障瞬间产生的高温高压破坏管路和用水设备。
        </p>
      </div>
    </Card>
    <!-- </van-tab> -->

    <!-- </van-tabs> -->

    <BackTop></BackTop>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import eSign from "../mobieChildCompenent/eSign.vue";
export default {
  data() {
    return {
      active: 0,
      minute:0,
      dataobj: {
        SignImgs: "",
        WarrantyNum: "",
      },
      dataexobj: {},
      base_url: this.$file.url,
    };
  },
  computed: {},
  methods: {
    tabchange(index, change) {},
    GetData() {
      var that = this;
      that.$http
        .get("/Customer/GetCustomerByDi", {
          params: {
            id: that.$route.params.id,
          },
        })
        .then((res) => {
          that.dataobj = res.data.Data;
          var start_date = new Date(res.data.Data.StartGuaranteedTime);
          var end_date = new Date(res.data.Data.EndGuaranteedTime);
          var it_msec = end_date.getTime() - start_date.getTime();
          var minute2 = parseInt(it_msec/ (1000 * 60));
          if (
            res.data.Data.SignImgs != null &&
            res.data.Data.SignImgs.length > 0
          ) {
            var imgStr = res.data.Data.SignImgs;
            that.dataobj.SignImgs =
              that.base_url +
              "Images/upload/" +
              res.data.Data.WarrantyNum +
              "/sign/" +
              imgStr +
              ".jpg";
            that.dataobj.StartGuaranteedTime = that.dateFilter(that.dataobj.StartGuaranteedTime);
            that.dataobj.EndGuaranteedTime = that.dateFilter(that.dataobj.EndGuaranteedTime);
            that.dataobj._CreateTime = that.dateFilter(that.dataobj._CreateTime);
            that.minute=minute2;
          }
        });
    },
    uploadSuccess(fileName) {
      var that = this;
      this.$http
        .get("/Customer/SaveSignImgsPath", {
          params: {
            WarrantyNums: fileName,
            id: that.$route.params.id,
          },
        })
        .then((res) => {
          if (res.data == "success") {
            that.$Modal.info({ title: "提示", content: "签署成功" });            
          }else{
            that.$Modal.warning({ title: "提示", content: res.data });
          }
          that.GetData();
        });
    },
    reSign(){      
      this.dataobj.SignImgs="";      
    },
    dateFilter: function(input) { 
      var d = new Date(input); 
      var year = d.getFullYear(); 
      var month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : "" + (d.getMonth() + 1); 
      var day = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate(); 
      var hour = d.getHours() < 10 ? "0" + d.getHours() : "" + d.getHours(); 
      var minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : "" + d.getMinutes(); 
      var seconds = d.getSeconds() < 10 ? "0" + d.getSeconds() : "" + d.getSeconds(); 
      return ( year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds ); 
    }
  },
  created() {
    console.log(this.$route.params.id);
    this.GetData();
    // this.GetEXData();
  },
  components: {
    "e-Sign": eSign,
  },
};
</script>

<style scoped>
.mo-cell {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 15px;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  line-height: 24px;
  position: relative;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.02);
  margin-top: 3px;
}
.mo-cell::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #e5e5e5;
}
.mo-img-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  justify-content: flex-start;
}
.mo-img-cell {
  width: 12vh;
  height: 12vh;
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}
.van-uploader i {
  margin-top: 2.5vh;
  font-size: 8vh;
}
.mo-imgupload {
  margin-bottom: 20vh;
  /* text-align: center; */
}
.mo-img-deletespan {
  float: right;
  margin: 3px;
  color: white;
  font-size: large;
  /* background-color: black; */
}
.row {
  margin-top: 5px;
}

/* 样式 */
    .table_detail{
      overflow:auto;
      width: 100%;
      /* 固定滚动高度 */
      max-height: calc(100vh - 220px);
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      border-right: 0;
      
    }
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }
    td, th {
      border-right: 1px solid #EBEBEB;
      border-bottom :1px solid #EBEBEB;
      width:100px;
      height: 35px;
      color: #333;
      box-sizing: border-box;
      background-color: #ffffff;
      font-size: 12px;
      text-align: center;
      padding: 5px 5px 5px 0;
    }
    th {
      background: #FAFAFA;
      color: #999;
      font-weight: normal;
    }
    /* 固定表头*/
    thead tr th {
      position:sticky;
      top:0;
    }
    th:first-child{
      z-index:2;
    }
    /* 固定表头*/
    tfoot tr th {
      position:sticky;
      bottom:0;
      color: #333;
      font-weight: bold;
      border-bottom: 0;
    }
    th:first-child{
      z-index:2;
    }
</style>
